<template>
	<div>
		<scabe
			class="mx-2 my-3 border-b border-black"
			:name="this.$route.params.name"
		/>
		<div class="py-2 border-b border-black w-3/5 mx-2">
			<p class="mt-2 font-bold">Cotización</p>
			<p v-if="vendedorCompletarCotizacion != ''" class="mt-2">
				Productos a completar
			</p>
		</div>
		<div>
			<div
				v-for="(productos, k) in vendedorCompletarCotizacion"
				:key="k"
				class="rounded-lg border border-secundB bg-secund my-4 mx-2 p-1 font-bold"
			>
				<div class="underline mb-2">
					{{ firstLetterUpperCase(productos.precios[0].producto.producto) }}
				</div>
				<div>Solicitado: {{ firstLetterUpperCase(productos.comentario) }}</div>
				<input
					type="number"
					v-model="productos.cantidad"
					class="my-2 w-30 rounded-lg text-center"
					min="0"
					max="1000"
					step=".001"
				/><span class="ml-2">{{
					firstLetterUpperCase(productos.precios[0].unidad)
				}}</span>
				<div class="my-1">Proveedores Asociados:</div>
				<div>
					<select
						class="p-1 w-56 bg-white border border-secundB rounded-lg"
						name=""
						id=""
					>
						<option
							v-for="(local, j) in productos.precios[0].producto.precio"
							:key="j"
							:value="j"
						>
							{{ firstLetterUpperCase(local.local[0].nombre) }}
						</option>
					</select>
				</div>
			</div>
		</div>
		<div
			v-if="verificarPendienteCotizacion != ''"
			class="py-2 border-b border-black w-3/5 mx-2"
		>
			<p class="mt-2">Lista de productos nuevos</p>
		</div>
		<div>
			<div
				v-for="(productos, k) in verificarPendienteCotizacion"
				:key="k"
				class="rounded-lg border border-secundB bg-secund my-4 mx-2 p-1 font-bold"
			>
				<div class="underline mb-2">
					{{ firstLetterUpperCase(productos.producto) }}
				</div>
				<div class="flex items-center mt-2">
					<p class="mx-2">Unidad de cobro</p>
					<select name="unidad" v-model="productos.unidad">
						<option value="kg" selected>Kg</option>
						<option value="cesta">Cesta</option>
						<option value="manojo">Manojo</option>
						<option value="unid">Unid</option>
						<option value="saco">Saco</option>
					</select>
				</div>
				<div class="flex items-center mt-2">
					<p class="mx-2">Precio en $</p>
					<input
						type="number"
						v-model="productos.precio"
						class="border border-black rounded-md text-center"
						min="0"
						max="1000"
						step=".001"
					/>
				</div>
				<div class="flex mt-5">
					<input class="mr-3" type="checkbox" v-model="productoIVA[k]" />
					<p>¿Aplica IVA?</p>
				</div>
				<div class="flex items-center my-2 mt-5">
					<p class="mx-2">Local</p>
					<select name="locales" v-model="productos.local_id">
						<option
							v-for="(local, j) in localesLista"
							:key="j"
							:value="local.nombre"
						>
							{{ firstLetterUpperCase(local.nombre) }}
						</option>
					</select>
				</div>
				<div>
					<div class="flex mt-5">
						<input
							class="mr-3"
							type="checkbox"
							v-model="checkNuevoProveedor[k]"
						/>
						<p>¿Proveedor no existe?</p>
					</div>
					<div v-if="checkNuevoProveedor[k]">
						<textarea
							class="border border-black rounded-md"
							type="text"
							v-model="nuevoLocal[k]"
							placeholder="Nombre, datos, ubicación y teléfono del proveedor."
						/>
					</div>
					<div class="flex mt-5">
						<input
							class="mr-3"
							type="checkbox"
							v-model="existenciaProducto[k]"
						/>
						<p>Producto sin existencia</p>
					</div>
				</div>
			</div>
		</div>
		<botonP
			@click="completarCotizacion()"
			botonpText="Enviar"
			class="flex items-center justify-center my-6"
		/>
	</div>
</template>

<script>
import botonP from "@/components/botonPrimario.vue";
import scabe from "@/components/subCabeceraV.vue";
import gql from "graphql-tag";
import { firstLetterUpperCase } from "../functions.js";
import errorMutationMixin from "../mixins/errorMutationMixin.js";

export default {
	data() {
		return {
			checkNuevoProveedor: [],
			nuevoLocal: [],
			existenciaProducto: [],
			productoIVA: [],
			dato: [],
			me: [],
			verificarPendienteCotizacion: {},
			vendedorCompletarCotizacion: {},
			localesLista: {}
		};
	},
	components: {
		botonP,
		scabe
	},
	mixins: [errorMutationMixin],
	async created() {
		let arrayLocalStorage = JSON.parse(
			window.localStorage.getItem("cotizacionesVendedor")
		);
		await this.idUsuarioQuery();
		let cotizacionArray = [];
		if (arrayLocalStorage != null) {
			cotizacionArray = arrayLocalStorage.filter(
				e => e.cotizacionId == this.$route.params.id
			);
		}
		if (cotizacionArray.length > 0) {
			this.vendedorCompletarCotizacion = cotizacionArray[0].completarCotizacion;
			this.verificarPendienteCotizacion =
				cotizacionArray[0].pendienteCotizacion;
			console.log(cotizacionArray);
			this.nuevoLocal = cotizacionArray[0].nuevoLocal;
			this.productoIVA = cotizacionArray[0].productoIVA;
			this.existenciaProducto = cotizacionArray[0].existenciaProducto;
			this.checkNuevoProveedor = cotizacionArray[0].checkNuevoProveedor;
		} else {
			await this.verificarPendienteCotizacionQuery();
			await this.vendedorCompletarCotizacionQuery();
		}
		await this.localesListaQuery();
	},
	methods: {
		firstLetterUpperCase,
		async cotizacionVendedorMutation(id, dataJson) {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/cotizacionPesosVendedor.gql"),
					variables: {
						cotizacion_id: parseInt(id),
						lista_pesos: dataJson
					}
				})
				.then(async () => {
					this.$store.state.isLoading = false;
					await this.productoNuevo();
				})
				.catch(error => {
					this.errorMutation("cotizacionVendedorMutation", error, dataJson);
					alert("Error para completar cotización, por favor vuelva a intentar");
					this.mensaje = error;
					this.showMessage = true;
					this.$store.state.isLoading = false;
				});
		},
		async localesListaQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/proveedores.gql"),
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.localesLista = data.data.localesLista;
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
					alert("Error en carga de datos. Por favor intente nuevamente.");
				});
		},
		async idUsuarioQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/idUsuario.gql")
				})
				.then(data => {
					this.me = data.data.me;
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
					alert("Error en carga de datos. Por favor intente nuevamente.");
				});
		},
		async verificarPendienteCotizacionQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/verificarPendienteCotizacion.gql"),
					variables: {
						cotizacion_id: this.$route.params.id,
						producto: "null",
						productoEmptyStringFilter: "",
						check: "0"
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.verificarPendienteCotizacion =
						data.data.verificarPendienteCotizacion;
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
					alert("Error en carga de datos. Por favor intente nuevamente.");
				});
		},
		async vendedorCompletarCotizacionQuery() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.query({
					query: require("@/graphql/queries/vendedorCompletarCotizacion.gql"),
					variables: {
						cotizacion_id: this.$route.params.id
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					let arraySpliceCotizacion = [];
					let arrayPrecios = {};
					for (
						let index = 0;
						index < data.data.vendedorCompletarCotizacion.length;
						index++
					) {
						arrayPrecios =
							data.data.vendedorCompletarCotizacion[index].precios[0].producto
								.precio;
						//ordenar los locales por precio
						console.log("antes", arrayPrecios);
						for (let i = 0; i < arrayPrecios.length; i++) {
							for (let j = i + 1; j < arrayPrecios.length; j++) {
								if (
									parseFloat(arrayPrecios[i].precio_usd) *
										(1 - parseFloat(arrayPrecios[i].descuento) / 100) >
									parseFloat(arrayPrecios[j].precio_usd) *
										(1 - parseFloat(arrayPrecios[j].descuento) / 100)
								) {
									let aux = arrayPrecios[i];
									arrayPrecios[i] = arrayPrecios[j];
									arrayPrecios[j] = aux;
								}
							}
						}
						console.log("despues", arrayPrecios);
					}

					this.vendedorCompletarCotizacion =
						data.data.vendedorCompletarCotizacion;
					this.vendedorCompletarCotizacion.forEach((element, indexArray) => {
						element.precios[0].producto.precio.map((e, indexSubArray) => {
							if (
								e.existencia == 0 ||
								e.local[0].show == 0 ||
								e.precio_usd == 0
							) {
								arraySpliceCotizacion.push({
									indexArray: indexArray,
									indexSubArray: indexSubArray
								});
							}
						});
					});
					//Select para locales
					arraySpliceCotizacion = arraySpliceCotizacion.reverse();
					arraySpliceCotizacion.forEach(e =>
						this.vendedorCompletarCotizacion[
							e.indexArray
						].precios[0].producto.precio.splice(e.indexSubArray, 1)
					);
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					console.log(error);
					this.$store.state.isLoading = false;
					alert("Error en carga de datos. Por favor intente nuevamente.");
				});
		},
		productoNuevo() {
			let detener = false;
			let nombreLocalNuevo = "";
			let message = "";
			let ivaMessage = "";
			this.$store.state.isLoading = true;
			for (let i = 0; i < this.verificarPendienteCotizacion.length; i++) {
				if (!this.existenciaProducto[i]) {
					if (
						this.verificarPendienteCotizacion[i].id &&
						this.verificarPendienteCotizacion[i].unidad &&
						this.verificarPendienteCotizacion[i].precio
					) {
						if (this.checkNuevoProveedor[i]) {
							if (this.nuevoLocal[i] && this.nuevoLocal[i] != "") {
								nombreLocalNuevo =
									". Disponible con el proveedor no registrado. Los Datos son los siguientes: " +
									this.nuevoLocal[i];
							} else {
								detener = true;
							}
						} else {
							if (
								this.verificarPendienteCotizacion[i].local_id != "" &&
								this.verificarPendienteCotizacion[i].local_id
							) {
								nombreLocalNuevo =
									". Disponible con proveedor: " +
									this.verificarPendienteCotizacion[i].local_id;
							} else {
								detener = true;
							}
						}
					} else {
						detener = true;
					}
					if (!detener) {
						if (this.productoIVA[i]) {
							ivaMessage = " USD + IVA";
						} else {
							ivaMessage = " USD";
						}

						if (this.existenciaProducto[i])
							message =
								this.firstLetterUpperCase(
									this.verificarPendienteCotizacion[i].producto
								) + " sin existencia.";
						message =
							"Producto: " +
							this.firstLetterUpperCase(
								this.verificarPendienteCotizacion[i].producto
							) +
							". Unidad de venta: " +
							this.firstLetterUpperCase(
								this.verificarPendienteCotizacion[i].unidad
							) +
							". Precio de venta:  " +
							this.firstLetterUpperCase(
								this.verificarPendienteCotizacion[i].precio
							) +
							ivaMessage +
							nombreLocalNuevo;

						this.dato.push({
							user_emisor: this.me.id,
							user_receptor: "G-SU",
							cotizacion_id: this.$route.params.id,
							id: this.verificarPendienteCotizacion[i].id,
							message: message
						});
					} else {
						i = this.verificarPendienteCotizacion.length;
						detener = true;
					}
				} else {
					message =
						this.firstLetterUpperCase(
							this.verificarPendienteCotizacion[i].producto
						) + " sin existencia.";
					this.dato.push({
						user_emisor: this.me.id,
						user_receptor: "G-SU",
						cotizacion_id: this.$route.params.id,
						id: this.verificarPendienteCotizacion[i].id,
						message: message
					});
				}
			}
			if (!detener) {
				this.enviarNotificacion();
			} else {
				this.$store.state.isLoading = false;
				alert(
					"Debe completar todos los campos requeridos para el producto nuevo"
				);
			}
		},

		async completarCotizacion() {
			let detener = false;
			let completar = [];
			let cantidadBuscada;
			let totalD;
			let message = "";
			this.dato = [];
			//this.$store.state.isLoading = true;
			for (let i in this.vendedorCompletarCotizacion) {
				if (
					this.vendedorCompletarCotizacion[i].cantidad != "" &&
					this.vendedorCompletarCotizacion[i].cantidad
				) {
					cantidadBuscada = this.vendedorCompletarCotizacion[i].cantidad;
					totalD =
						cantidadBuscada *
						this.vendedorCompletarCotizacion[i].precios[0].precio_usd; //*
					//(1 -
					//this.vendedorCompletarCotizacion[i].precios[0].descuento / 100);
					completar.push({
						id: this.vendedorCompletarCotizacion[i].id,
						cantidad: parseFloat(cantidadBuscada),
						total_usd: parseFloat(totalD),
						notificar: false
					});
					message =
						"Cotización N° " +
						this.$route.params.id.padStart(4, "0") +
						" notificación: " +
						"Cantidad producto " +
						this.firstLetterUpperCase(
							this.vendedorCompletarCotizacion[i].precios[0].producto.producto
						) +
						" actualizada.";
					this.dato.push({
						user_emisor: this.me.id,
						cotizacion_id: this.$route.params.id,
						user_receptor: "G-SU",
						message: message
					});
				} else {
					this.$store.state.isLoading = false;
					alert(
						"Debe completar los campos requeridos de la cantidad del producto"
					);
					detener = true;
				}
			}
			if (!detener) {
				let arrayLocalStorage = JSON.parse(
					window.localStorage.getItem("cotizacionesVendedor")
				);
				let cotizacionArray = [];
				if (arrayLocalStorage != null) {
					cotizacionArray = arrayLocalStorage.filter(
						e => e.cotizacionId == this.$route.params.id
					);
				}
				if (cotizacionArray.length > 0) {
					arrayLocalStorage.map((e, index) => {
						if (e.cotizacionId == this.$route.params.id) {
							arrayLocalStorage[
								index
							].completarCotizacion = this.vendedorCompletarCotizacion;
							arrayLocalStorage[
								index
							].pendienteCotizacion = this.verificarPendienteCotizacion;
							arrayLocalStorage[
								index
							].existenciaProducto = this.existenciaProducto;
							arrayLocalStorage[index].nuevoLocal = this.nuevoLocal;
							arrayLocalStorage[index].productoIVA = this.productoIVA;
							arrayLocalStorage[
								index
							].checkNuevoProveedor = this.checkNuevoProveedor;
						}
					});
					window.localStorage.setItem(
						"cotizacionesVendedor",
						JSON.stringify(arrayLocalStorage)
					);
				} else {
					if (arrayLocalStorage != null) {
						arrayLocalStorage.push({
							cotizacionId: this.$route.params.id,
							completarCotizacion: this.vendedorCompletarCotizacion,
							pendienteCotizacion: this.verificarPendienteCotizacion,
							existenciaProducto: this.existenciaProducto,
							nuevoLocal: this.nuevoLocal,
							productoIVA: this.productoIVA,
							checkNuevoProveedor: this.checkNuevoProveedor
						});
						window.localStorage.setItem(
							"cotizacionesVendedor",
							JSON.stringify(arrayLocalStorage)
						);
					} else {
						window.localStorage.setItem(
							"cotizacionesVendedor",
							JSON.stringify([
								{
									cotizacionId: this.$route.params.id,
									completarCotizacion: this.vendedorCompletarCotizacion,
									pendienteCotizacion: this.verificarPendienteCotizacion,
									existenciaProducto: this.existenciaProducto,
									nuevoLocal: this.nuevoLocal,
									productoIVA: this.productoIVA,
									checkNuevoProveedor: this.checkNuevoProveedor
								}
							])
						);
					}
				}
				await this.$apollo
					.mutate({
						mutation: gql`
							mutation($data: [completarCotizacion!]) {
								completarCotizacionVendedor(data: $data) {
									id
								}
							}
						`,
						variables: {
							data: completar
						}
					})
					.then(async () => {
						await this.cotizacionVendedorMutation(
							this.$route.params.id,
							JSON.stringify(completar)
						);
					})
					.catch(error => {
						this.errorMutation(
							"completarCotizacionVendedor",
							error,
							JSON.stringify(completar)
						);
						this.mensaje = error;
						this.showMessage = true;
						this.$store.state.isLoading = false;
					});
			}
		},
		async enviarNotificacion() {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: gql`
						mutation($data: [ingresarNotificaciones]) {
							sendingNotification(data: $data) {
								id
							}
						}
					`,
					variables: {
						data: this.dato
					}
				})
				.then(async () => {
					await this.errorMutation(
						"sendingNotification",
						"exitoso",
						JSON.stringify(this.dato)
					);
					this.notificarBroadcasting();
					let arrayCotizacionesStorage = JSON.parse(
						window.localStorage.getItem("cotizacionesVendedor")
					);
					if (arrayCotizacionesStorage != null) {
						let indexPedido = arrayCotizacionesStorage.findIndex(
							e => parseInt(e.cotizacionId) == parseInt(this.$route.params.id)
						);
						if (indexPedido >= 0) {
							arrayCotizacionesStorage.splice(indexPedido, 1);
							window.localStorage.setItem(
								"cotizacionesVendedor",
								JSON.stringify(arrayCotizacionesStorage)
							);
						}
					}
					this.$store.state.isLoading = false;
					alert("Cotización enviada con exito, redireccionando...");
					setTimeout(() => {
						this.$router.push("/Vhome");
					}, 1500);
				})
				.catch(error => {
					this.errorMutation(
						"sendingNotification",
						error,
						JSON.stringify(this.dato)
					);
					this.$store.state.isLoading = false;
					alert("Error de comunicación, por favor vuelva a intentarlo.");
				});
		},
		notificarBroadcasting() {
			window.axios.post(
				process.env.VUE_APP_BACKEND_URL + "/tipoCambioMensaje",
				{
					message:
						"Cotización N°" +
						String(this.$route.params.id).padStart(4, "0") +
						" completada por vendedor " +
						this.firstLetterUpperCase(this.me.name),
					user_receptor: "G-SU",
					user_emisor: this.me.id,
					cotizacion_id: this.$route.params.id,
					check: "1"
				}
			);
		}
	}
};
</script>
