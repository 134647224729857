export default {
	methods: {
		async errorMutation(method, error, variable) {
			this.$store.state.isLoading = true;
			await this.$apollo
				.mutate({
					mutation: require("@/graphql/mutations/ErrorMutation.gql"),
					variables: {
						data: {
							vista: "VendedorPedido.vue",
							compra_id: String(this.$route.params.id),
							method: method,
							error: String(error),
							variable: variable
						}
					}
				})
				.then(() => {
					this.$store.state.isLoading = false;
				})
				.catch(error => {
					this.$store.state.isLoading = false;
					console.log(error);
					alert("Error de comunicación, por favor vuelva a intentar.");
				});
		}
	}
};
